App.modules.storiesSlider = (() => {
    let storiesSlider, currentStory, dialog;
    function setInstagram() {
        if (typeof instgrm != 'undefined') {
            instgrm.Embeds.process();
        } else {
            const script = document.createElement('script');
            script.setAttribute('defer', true)
            script.setAttribute('async', true)
            script.setAttribute('src', '//www.instagram.com/embed.js')
            document.head.appendChild(script)
        }
    }
    function setTiktok() {

        const script = document.createElement('script');
        script.setAttribute('defer', true)
        script.setAttribute('async', true)
        script.setAttribute('src', '//www.tiktok.com/embed.js')
        document.head.appendChild(script)

    }

    function videoCode(a) {
        const url = a.href;
        const ar = a.offsetWidth / a.offsetHeight;
        const w = Math.max(a.offsetWidth, (20 * window.innerWidth) / 100);
        if (url.includes('instagram')) {
            let code = getComment(a);
            if (code) {
                return { code, set: setInstagram };
            }
            return {
                code: `<blockquote class="instagram-media" data-instgrm-permalink="${url}" data-instgrm-version="14" style="min-width:initial !important;width:${w}px !important;aspect-ratio:${ar} !important">Post Instagram <a href="${url}">Voir sur instagram.com</a></blockquote>`, set: setInstagram
            };
        } else if (url.includes('tiktok')) {
            let code = getComment(a);
            if (code) {
                return { code, set: setTiktok };
            }

            const id = url.split('?')[0].split('/').pop()
            if (id) {
                return { code: `<blockquote class="tiktok-embed" cite="${url}" data-video-id="${id}" style="max-width: initial;min-width: initial;width:${w}px !important;aspect-ratio:${ar} !important" >TikTok</blockquote> `, set: setTiktok };
            }
        }
    }

    function closeStory() {
        dialog?.remove();
    }

    function openStory(article) {
        if (!article) return;
        const a = article.querySelector('a');
        const result = videoCode(a);
        if (result) {
            closeStory();
            currentStory = article;
            dialog = document.createElement('dialog');
            dialog.innerHTML = `<div>${result.code}</div><nav><button data-action="prev"></button><button data-action="next"></button></nav>`;

            storiesSlider.append(dialog)
            dialogStart();
            dialog.showModal();
            result.set();
            dialogEnd()
            return true;
        }
    }
    function dialogStart() {
        dialog.showModal();
        dialog.querySelector('div').dataset.loading = true
    }

    function dialogEnd() {
        setTimeout(() => {
            delete dialog.querySelector('div').dataset.loading;
        }, 1000)
    }
    return {

        start() {
            storiesSlider = document.querySelector('.stories-slider');
            if (!storiesSlider) return;

            storiesSlider.addEventListener('click', e => {
                const article = e.target.closest('article');

                if (article) {
                    if (openStory(article)) {
                        return e.preventDefault();
                    }
                }

                // });
                // storiesSlider.addEventListener('click', e => {
                const button = e.target.closest('button');
                if (button) {
                    const action = button.dataset.action;
                    if (!action) return;
                    e.preventDefault();

                    console.log(action);
                    if (action == 'next') {
                        openStory(currentStory.nextElementSibling)
                    } else {
                        openStory(currentStory.previousElementSibling)
                    }
                }
                if (e.target == dialog) {
                    closeStory()
                }

            })
        }
    }
})();

