App.modules.fiches = (() => {

	let fiche, logoEquipe, couleurLogo;

	return {
		start() {
			// logoEquipe = document.querySelector('#logoEquipe');

			fiche = {
				equipe: document.querySelector('[data-zone="fiche-equipe"]'),
				joueur: document.querySelector('[data-zone="fiche-joueur"]'),
				match: document.querySelector('[data-zone="fiche-match"]'),
				coachContainer: document.querySelector('[data-zone="fiche-equipe"] .coach-container'),
				header: document.querySelector('.nom-equipe'),
				stats: document.querySelector('[data-zone="fiche-equipe"] .stats-container'),
				palmares: document.querySelector('.palmares-container'),
				joueurCarriere: document.querySelector('.carriere-container'),
				joueurStatistiques: document.querySelector('[data-zone="fiche-joueur"] .stats-container'),
			};

			// si on est sur une des pages de la fiche equipe
			if (fiche.equipe) {

				// si on est sur la page des stats
				if (fiche.stats) {
					this.initChart();
					this.setBgBallon();
				}

			} else if (fiche.joueur) {

				if (fiche.joueurStatistiques) {
					this.switchStats();
					if(document.body.classList.contains('statistiques-match')) {
						this.creerDisquePasses();
					}
				}

			}

		},
		// activerBurgerOnglets() {
		// 	let bouton = fiche.onglets.querySelector('[data-action="onglets-fiche"]');
		// 	if (bouton) {
		// 		bouton.addEventListener('click', () => {
		// 			if (!fiche.onglets.dataset.ouvert) {
		// 				fiche.onglets.dataset.ouvert = true;
		// 			} else {
		// 				delete fiche.onglets.dataset.ouvert;
		// 			}
		// 		});
		// 	}
		// },
		initChart() {
			this.genererBar();

			this.calculDisque();
		},
		genererBar() {
			let bar = document.querySelector('#barContainer');
			let abscisse = bar.querySelector('[data-zone="abscisse"]')
			let canvas = bar.querySelector('[data-zone="canvas"]')

			let buts = {
				marques: {
					domicile: Number(bar.dataset.butsMarquesDomicile),
					exterieur: Number(bar.dataset.butsMarquesExterieur)
				},
				encaisses: {
					domicile: Number(bar.dataset.butsEncaissesDomicile),
					exterieur: Number(bar.dataset.butsEncaissesExterieur)
				}
			}

			let totalbm = buts.marques.domicile + buts.marques.exterieur;
			let totalbe = buts.encaisses.domicile + buts.encaisses.exterieur;

			let total = Math.max(totalbm, totalbe);
			let intervale = Math.ceil(total / 5) + 1;
			console.log('total', total, 'intervale', intervale)
			let numeros = [];
			for (let i = 0; i < intervale; i++) {
				numeros.push(`<span>${i * 5}</span>`);
			}
			abscisse.innerHTML = numeros.join('');

			let delay = 0;
			['marques', 'encaisses'].forEach(type => {
				['domicile', 'exterieur'].forEach(lieu => {
					delay += 400;
					setTimeout(() => {
						let valeur = buts[type][lieu];
						let percent = valeur * 100 / (intervale * 5);
						canvas.querySelector(`[data-zone="${type}"] [data-zone="${lieu}"]`).style.width = percent + '%';
						canvas.querySelector(`[data-zone="${type}"] [data-zone="${lieu}"] span`).innerHTML = valeur;
					}, delay)

				})
				delay += 400;
			})
		},
		genererBarOld() {
			let bar = document.querySelector('#barContainer');
			let butsMarques = [bar.dataset.butsMarquesDomicile, bar.dataset.butsMarquesExterieur];
			let butsEncaisses = [bar.dataset.butsEncaissesDomicile, bar.dataset.butsEncaissesExterieur];
			console.log(butsMarques, butsEncaisses)
			const dataButs = [
				[
					butsMarques, ['#bmd', '#bme']
				],
				[
					butsEncaisses, ['#bed', '#bee']
				],
			];

			const total = this.calculButsTotal(butsMarques, butsEncaisses);

			this.genererLabels(total);

			let bars = [];
			dataButs.forEach(e => {
				bars.push(this.animerBar(e[0], total, e[1]))
			});
		},
		calculButsTotal(butsMarques, butsEncaisses) {
			let totalMarques = butsMarques[0] + butsMarques[1];
			let totalEncaisses = butsEncaisses[0] + butsEncaisses[1];

			if (totalMarques > totalEncaisses) {
				return totalMarques;
			} else {
				return totalEncaisses;
			}

		},
		animerBar(buts, totalButs, elements) {
			const butsDomicile = buts[0];
			const butsExterieur = buts[1];

			let pButsDomicile = 0;
			let pButsExterieur = 0;

			if (butsDomicile !== 0) {
				pButsDomicile = ((100 / (0.8 / (butsDomicile / totalButs))) / 2) + 3;
			}
			if (butsExterieur !== 0) {
				pButsExterieur = ((100 / (0.8 / (butsExterieur / totalButs))) / 2) + 3;
			}

			let xButsExterieur = pButsDomicile + 20;

			let barDomicile = document.querySelector(elements[0]);
			let barExterieur = document.querySelector(elements[1]);

			barDomicile.setAttribute('width', pButsDomicile + '%');
			barExterieur.setAttribute('x', xButsExterieur + '%');
			barExterieur.setAttribute('width', pButsExterieur + '%');

			barDomicile.setAttribute('data-buts-dom', butsDomicile);
			barExterieur.setAttribute('data-buts-ext', butsExterieur);

			let animateDomicile = this.createAnimationSvgRect(barDomicile.getAttribute('width'));
			let animateExterieur = this.createAnimationSvgRect(barExterieur.getAttribute('width'));

			barDomicile.style.fill = "var(--color-fiches-fond)";
			barExterieur.style.fill = "#a7a7a7";
			barDomicile.appendChild(animateDomicile);
			barExterieur.appendChild(animateExterieur);
			return [barDomicile, barExterieur];
		},
		genererLabels(total) {
			let steps;

			if (total > 35) {
				steps = 10;
			} else {
				steps = 5;
			}

			let xAxisLabel = document.querySelector('.x-labels');
			if (xAxisLabel) {
				let labels = this.creerLabels(steps, total);

				labels.forEach(e => xAxisLabel.appendChild(e))
			}
		},
		creerLabels(steps, total) {
			let labelsFinaux = [];
			let labels = this.calculerXLabel(steps, total);
			labels.forEach(label => {

				let tmp = document.createElementNS('http://www.w3.org/2000/svg', 'text');
				tmp.textContent = label[0];
				tmp.setAttribute('x', label[1]);
				tmp.setAttribute('y', 220);
				tmp.setAttribute('fill', '#000');
				tmp.setAttribute('class', 'gros');
				labelsFinaux.push(tmp)
			});

			return labelsFinaux;
		},
		calculerXLabel(steps, total) {

			let nbLabel;
			if (steps > 30) {
				nbLabel = 5;
			} else {
				nbLabel = 6;
			}
			let lastIndex = nbLabel - 1;

			let pourcentageXLabels = [];
			for (let index = 0; index < nbLabel; index++) {
				if (index == 0) {
					pourcentageXLabels.push([0, '20%']);
				} else if (index == lastIndex) {
					pourcentageXLabels.push([total, '88%']);
				} else {
					let marque = steps * index;
					let calculXLabel = (
						(100 / (0.8 / (marque / total))) / 2
					) + 23;
					if (calculXLabel < 86 && calculXLabel > 22) {
						pourcentageXLabels.push([marque, calculXLabel + '%']);
					}
				}
			}
			return pourcentageXLabels;
		},
		getRandomInt(max) {
			return Math.floor(Math.random() * max);
		},
		calculDisque() {
			let disque = document.querySelector('#disqueContainer');
			let victoires = Number(disque.dataset.victoires);
			let nuls = Number(disque.dataset.nuls);
			let defaites = Number(disque.dataset.defaites);
			let total = victoires + nuls + defaites;

			console.log(victoires, nuls, defaites)

			let pVictoires = (victoires / total) * 100;
			let pNuls = (nuls / total) * 100;
			let pDefaites = (defaites / total) * 100;

			let dVictoires = document.querySelector("#victoires");
			let dNuls = document.querySelector("#nuls");
			let dDefaites = document.querySelector("#defaites");

			this.animerDisque(dVictoires, pVictoires, 0);

			this.animerDisque(dNuls, pNuls, 100 - pVictoires);

			this.animerDisque(dDefaites, pDefaites, 100 - (pVictoires + pNuls));
		},
		animerDisque(disque, p, ajout) {
			let offset = 25;
			disque.style.transition =
				"stroke-dasharray 0.5s ease-in-out, stroke-dashoffset 0.5s ease-in-out";
			disque.style.strokeDasharray = p + " " + (100 - p);
			disque.style.strokeDashoffset = offset + ajout;
			disque.setAttribute("stroke", disque.getAttribute("data-stroke"));
		},
		createAnimationSvgRect(width) {
			let animation = document.createElementNS('http://www.w3.org/2000/svg', 'animate');

			animation.setAttribute('attributeType', 'XML');
			animation.setAttribute('attributeName', 'width');
			animation.setAttribute('from', '0');
			animation.setAttribute('to', width);
			animation.setAttribute('dur', '1s');
			animation.setAttribute('repeatCount', '1');

			return animation;
		},
		setBgBallon() {
			let ballon = document.querySelector('.possession svg');
			if (ballon) {
				 let possession = document.querySelector('[data-zone="possession-valeur"]').innerHTML;
				 setTimeout(() => {
					document.querySelector('.possession-svg span').style.height=possession;
				 },2500);
				// let possession = document.querySelector('[data-zone="possession-valeur"]').innerHTML.replace('%', '');
				// let defs = this.creerAnimationBallon(possession)
				// ballon.appendChild(defs)
			}
		},
		creerAnimationBallon(pourcentage) {
			let defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
			let linearGradient = document.createElementNS('http://www.w3.org/2000/svg', 'linearGradient');

			let linearGradientAttr = {
				id: 'a',
				x1: 0.5,
				x2: 0.5,
				y1: 1,
				y2: 0,
			};
			for (let attribut in linearGradientAttr) {
				linearGradient.setAttribute(attribut, linearGradientAttr[attribut]);
			}

			let stops = [];
			let stopsAttr = {
				1: {
					'offset': "0%",
					'stop-opacity': "1",
				},
				2: {
					'offset': pourcentage + "%",
					'stop-opacity': "1",
				},
				3: {
					'offset': pourcentage + "%",
					'stop-opacity': "0",
				},
				4: {
					'offset': "100%",
					'stop-opacity': "0",
				},
			}

			for (let attribut in stopsAttr) {
				let stop = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
				console.log(stopsAttr[attribut]['stop-opacity'])
				stop.setAttribute('offset', stopsAttr[attribut]['offset']);
				stop.setAttribute('stop-opacity', stopsAttr[attribut]['stop-opacity']);
				stop.setAttribute('stop-color', "var(--color-fiches-fond)");
				linearGradient.appendChild(stop);
			}
			defs.appendChild(linearGradient);
			return defs;
		},
		setPhotosCoachsMatch() {
			let coachs = document.querySelectorAll('.coach-container');

			coachs.forEach(coach => {
				// this.setPhotoTransparente(coach);
			});

		},
		setPhotoTransparente(element) {
			if (element) {
				fondTransparent(element.querySelector('figure img'));
			}
		},
		setCouleurLogosCompetition(couleur) {
			let logos = document.querySelector('.palmares-container');
			let classe = '';

			if (couleur == '#fff') {
				classe = 'logo-en-blanc';
			} else {
				classe = 'logo-en-noir';
			}

			logos.classList.add(classe);
		},
		creerJoueurCarriere() {

			let carriere = this.genererFausseCarriere();

			let totalDureeCarriere = carriere.splice(carriere.length - 1);
			let totalClubs = carriere.length;


			carriere.forEach((etape, index) => {
				let tmpI = index + 1;
				let label;

				if (tmpI % 2) {
					label = this.genererCarriere('top', etape, totalDureeCarriere, tmpI, totalClubs);

					label.innerHTML = '';
					document.querySelector('section.bottom').appendChild(label);
				} else {
					label = this.genererCarriere('bottom', etape, totalDureeCarriere, tmpI, totalClubs);
					label.innerHTML = '';
					document.querySelector('section.top').appendChild(label);
				}

			});

			this.animerTimelineCarriere();

		},
		switchStats() {
			let filtres = document.querySelectorAll('.filtre');

			filtres.forEach(e => {

				e.addEventListener('click', (element) => {
					element.preventDefault();
					if (e.classList.contains('selectionne')) {
						return false;
					}

					let id = e.id;

					let toShowContainer = document.querySelector(`div[class*="${id}-stats-container"]`);
					let toHide = document.querySelector('.filtre.selectionne');
					let toHideId = toHide.id;
					let toHideContainer = document.querySelector(`div[class*="${toHideId}-stats-container"]`);

					toHide.classList.remove('selectionne');
					e.classList.add('selectionne');

					console.log(toHideContainer);
					toHideContainer.classList.remove('db');
					toShowContainer.classList.add('db');

				})

			});

		},
		creerDisquePasses() {
			let pourcentage = document.querySelector('.graph span').textContent;
			let disquePasses = document.querySelector('.match-stats-container');

			pourcentage = pourcentage.substring(0, pourcentage.length - 1);


			let dVictoires = document.querySelector("#passesReussies");
			this.animerDisque(dVictoires, pourcentage, 0);




		},


	}

})();