
App.modules.ads = (() => {
    let grandeImage;
    let petiteImage;
    let cible;
    let ouvrirExpandDelay;
    let capping;


    return {
        start() {
            if (document.location.hash.includes('expand')) {
                let capping = document.location.hash.includes('capping') ? 2 : false;

                App.modules.ads.activerExpand({
                    petiteImage: '/images/ads/expand-petit.jpg',
                    grandeImage: '/images/ads/expand-grand.jpg',
                    petiteImageMobile: '/images/ads/expand-petit-mobile.jpg',
                    grandeImageMobile: '/images/ads/expand-grand-mobile.jpg',
                    cible: 'https://www.graff.com/eu-fr/home/',
                    capping
                })
            }
        },
        activerExpand(params = {}) {
            grandeImage = params?.grandeImage || false;
            petiteImage = params?.petiteImage || false;
            grandeImageMobile = params?.grandeImageMobile || grandeImage;
            petiteImageMobile = params?.petiteImageMobile || petiteImage;

            cible = params?.cible || false;
            ouvrirExpandDelay = params?.ouvrirExpandDelay || 10;
            capping = params?.capping || false;

            document.querySelector('.userExpand')?.remove();
            let html = `
            <span class="spinner"><span class="spinner-icon"></span></span>
            <span class="petite desktop" style="background-image: url('${petiteImage}');"></span>
            <span class="grande desktop" style="background-image: url('${grandeImage}');"></span>
            <span class="petite mobile" style="background-image: url('${petiteImageMobile}');"></span>
            <span class="grande mobile" style="background-image: url('${grandeImageMobile}');"></span>
            `;
            let userExpand = document.createElement('a');
            userExpand.classList.add('userExpand');
            userExpand.innerHTML = html;
            userExpand.href = cible;
            userExpand.target = '_blank';
            document.body.prepend(userExpand);

            document.querySelector('html').dataset.expand = 'true';

            if (!capping || isTimeToDoTask('expand', capping)) {
                console.log('capping expand ok');
                setTimeout(() => {
                    App.modules.ads.ouvrirEtFermerExpand();
                }, 500);
            }
            document.addEventListener('scroll-start', App.modules.ads.fermerExpand);

            userExpand.addEventListener('click', (e) => {
                console.log(e.target, e.target.closest('.petite.mobile'));
                if (e.target.closest('.petite.mobile')) {
                    if (!App.modules.ads.expandOuvert()) {
                        e.preventDefault();
                        App.modules.ads.ouvrirExpand();
                    }
                }
            });
        },
        fermerExpand() {
            if (document.querySelector('html').dataset.expandOpen)
                delete document.querySelector('html').dataset.expandOpen;
        },
        ouvrirExpand() {
            document.querySelector('html').dataset.expandOpen = 'true';
        },
        expandOuvert() {
            return document.querySelector('html').dataset?.expandOpen;
        },
        ouvrirEtFermerExpand() {
            App.modules.ads.ouvrirExpand();
            setTimeout(() => {
                App.modules.ads.fermerExpand();
            }, ouvrirExpandDelay * 1000);
        },
        activerInterstitiel(params) {
            if (document.querySelector('#interstitiel')) return;

            const capping = params?.capping;
            const key = 'capping-' + md5(JSON.stringify(params))
            const image = params?.image;
            if (!image) return;
            const lien = params?.lien;
            if (!lien) return;
            const duree = params?.duree;

            if (capping) {
                const storedValue = localStorage.getItem(key);
                if (storedValue) {
                    const { date } = JSON.parse(storedValue);
                    const today = new Date().toISOString().split('T')[0];
                    if (date === today) {
                        return;
                    }
                }
                const data = { date: new Date().toISOString().split('T')[0] };
                localStorage.setItem(key, JSON.stringify(data));
            }

            const interstitiel = document.createElement('dialog');
            interstitiel.id = 'interstitiel';
            document.body.append(interstitiel);
            interstitiel.innerHTML = `
                <button type="button"></button>
                <a href="${lien}" target="_blank"><img src="${image}"></a>
            `
            interstitiel.showModal();
            interstitiel.addEventListener('click', e => {
                const button = e.target.closest('button');
                if (!button) return;
                if (interstitiel.dataset.attendre) return;
                interstitiel.close();
            })
            if (duree) {
                interstitiel.dataset.attendre = true;
                let compteur = duree;
                interstitiel.querySelector('button').innerHTML = compteur;
                const st = setInterval(() => {
                    interstitiel.querySelector('button').innerHTML = --compteur
                    if (compteur == 0) {
                        interstitiel.querySelector('button').innerHTML = '';
                        clearInterval(st);
                        delete interstitiel.dataset.attendre;
                    }
                }, 1000)
            }
            return true;
        },
        activerHabillage(params) {
            let html = document.querySelector('html');
            html.dataset.arche = 'true';

            // let une = document.querySelectorAll('[data-zone="une"] > .article[data-mode="auto"]')[1] || false;
            // if (une) {
            //     // une.dataset.mode = "normal";
            // }
            // console.log(params);
            let style = [];

            let body_selector = 'body:not(:is([data-breakpoint="mini"], [data-breakpoint="petit"], [data-breakpoint="moyen"]))'
            style.push(`.habillage {height : ${params?.hauteur}}`);
            if (params?.sticky) {
                style.push(`.crea-habillage {background-color : ${params?.fond};}`);
            } else {
                style.push(`.crea-habillage {background-color : ${params?.fond}; background-image : url(${params?.image})}`);
            }
            if (params.zoom) {
                let zoom = params.zoom / 100;
                if (zoom) {
                    style.push(`.crea-habillage {scale : ${zoom}}`);
                }
            }
            if (params.fixe) {
                style.push(`.crea-habillage {position:fixed !important}`);
            }
            let tag = this.habillageStyleTag()
            tag.innerHTML = style.map(item => body_selector + ' ' + item).join('\n');
            const crea = document.querySelector('.crea-habillage');
            crea.setAttribute('href', params?.lien);
            if (params?.sticky) {
                crea.classList.add('sticky');
                crea.innerHTML = `<img src="${params?.image}">`;
            }
            if (params.pixel) {
                let div = document.createElement('div');
                div.innerHTML = params.pixel;
                div.id = 'pixel-habillage';
                document.body.append(div);
            }
            const headerHeight = document.querySelector('.header').offsetHeight;
            const habillage = document.querySelector('.crea-habillage');
            habillage.style.top = headerHeight + 'px';
            window.addEventListener('scroll', function () {
                habillage.style.top = (window.scrollY < headerHeight) ? (headerHeight - window.scrollY + 'px') : 0;
            });

        },
        habillageStyleTag() {
            let tag = document.querySelector('head style#habillage');
            if (!tag) {
                tag = document.createElement('style');
                tag.id = 'habillage'
                document.querySelector('head').appendChild(tag)
            }
            return tag;
        }
    }
})();



/*



            console.log(params);
            let html = document.querySelector('html');
            html.dataset.arche = 'true';
            html.style.background = params?.fond;
            let style = [
                `background: url(${params?.image}) no-repeat top center ${params?.fond}`,
                `background-size: ${params.zoom}% auto`,
                `padding-top: ${params?.hauteur}`,
            ];
            if (params?.fixed) {
                let h = document.querySelector('header.header').getBoundingClientRect().height
                style.push(`background-attachment: fixed`);
                style.push(`background-position: center ${h}px`);
            }
            document.querySelector('[data-arche="true"] body > #main').setAttribute('style', style.join(';'));
            document.addEventListener('click', e => {
                if (e.target.id == 'main') {
                    window.open(params.lien, '_blank');
                }
            })
*/